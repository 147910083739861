import { getDistributionInfo } from '@/apis/distribution';
import { IDistributionInfo } from '@/interface/distribution';
import { useRequest } from 'ahooks';
import { Col, Row } from 'antd';
import { useState } from 'react';
import './home.less';
import { useAppSelector } from '@/stores/useReduxHook';

export default function HomePage() {
  const { userInfo } = useAppSelector(state => state.appGlobal);
  /**
   * 请求当前用户数据
   */
  const [distributionInfo, setDistributionInfo] = useState<IDistributionInfo>();
  useRequest(getDistributionInfo, {
    onSuccess: res => {
      if (res?.data) {
        setDistributionInfo(res.data);
      }
    },
  });

  return (
    <div>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>用户名</h3>
            <div className="item-content">{distributionInfo?.name || userInfo?.nickname + userInfo?.phone}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>全部金额</h3>
            <div className="item-content">&yen;{distributionInfo?.balance}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>可提现金额</h3>
            <div className="item-content">&yen;{distributionInfo?.freeBalance}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>冻结金额</h3>
            <div className="item-content">&yen;{distributionInfo?.freezeBalance}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>用户等级</h3>
            <div className="item-content">{distributionInfo?.memberLevel}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>一级用户数</h3>
            <div className="item-content">{distributionInfo?.firstInviteUserCount}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>二级用户数</h3>
            <div className="item-content">{distributionInfo?.secondInviteUserCount}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <div className="item">
            <h3>开通时间</h3>
            <div className="item-content">{distributionInfo?.cerateTime}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
