import React, { useRef } from 'react';
import { Card, Select } from 'antd';
import MyTable from '@/components/table';
import { getOrderList } from '@/apis/distribution';

export default function OrderList() {
  const tableRef: RefType = useRef();

  // 搜索栏配置项
  const searchItemList = [
    {
      key: 'orderType',
      slot: (
        <Select
          placeholder="请选择订单类型"
          allowClear
          options={[
            { value: 1, label: '一级分销订单' },
            { value: 2, label: '二级分销订单' },
            { value: 3, label: '三级分销订单' },
          ]}
        />
      ),
      initialValue: 1,
    },
  ];

  const onSearchItemChange = changedField => {
    console.log(changedField);
  };

  const columns = [
    {
      title: '订单号',
      align: 'center',
      dataIndex: 'orderNo',
    },
    {
      title: '用户名',
      align: 'center',
      dataIndex: 'userName',
    },
    {
      title: '商品名称',
      align: 'center',
      dataIndex: 'itemName',
    },
    {
      title: '订单金额',
      align: 'center',
      dataIndex: 'amount',
    },
    {
      title: '分成金额',
      align: 'center',
      dataIndex: 'brokerage',
    },
    {
      title: '订单时间',
      align: 'center',
      dataIndex: 'orderTime',
    },
  ];
  return (
    <Card>
      <MyTable
        apiFun={getOrderList}
        columns={columns}
        ref={tableRef}
        searchItemList={searchItemList}
        onFieldsChange={onSearchItemChange}
      />
    </Card>
  );
}
