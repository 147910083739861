import { LogoConfig } from '@/config/ImagePath';
import { LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout, Dropdown, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxHook';
import { useRequest } from 'ahooks';
import { setLogged, setUserInfo } from '@/stores/slicers/global';
import { logOut } from '@/apis/user';

const { Header } = Layout;

interface HeaderProps {
  collapsed: boolean;
  toggle: () => void;
  breadcrumb: React.ReactNode;
}

type Action = 'userSetting' | 'logout';

const HeaderComponent = ({ collapsed, toggle, breadcrumb }: HeaderProps) => {
  const { logged, userInfo } = useAppSelector(state => state.appGlobal);
  const { device } = { device: 'DESKTOP' }; //TODO: get from redux
  const navigate = useNavigate();

  const onActionClick = async (action: Action) => {
    switch (action) {
      case 'userSetting':
        return;
      case 'logout': {
        runLoginOut();
        return;
      }
    }
  };

  /**
   * 退出登录
   */
  const dispatch = useAppDispatch();
  const { run: runLoginOut } = useRequest(logOut, {
    manual: true,
    onSuccess: res => {
      console.log('res', res);
      if (res) {
        localStorage.removeItem('fly_user_token');
        dispatch(setUserInfo(null));
        dispatch(setLogged(false));
        navigate('/login');
      }
    },
  });

  const toLogin = () => {
    navigate('/login');
  };

  const menus: MenuProps = {
    items: [
      {
        key: '1',
        icon: <LogoutOutlined />,
        label: <span onClick={() => onActionClick('logout')}>退出登录</span>,
      },
    ],
  };
  return (
    <Header className="layout-page-header">
      {device !== 'MOBILE' && (
        <div className="logo" style={{ width: collapsed ? 80 : 200 }}>
          <img src={LogoConfig.LogoPath} alt="飞链云版图" style={{ marginRight: collapsed ? '2px' : '20px' }} />
          {collapsed ? null : '飞链云版图'}
        </div>
      )}
      <div className="layout-page-header-main">
        <div className="bread">
          <div onClick={toggle}>
            <span id="sidebar-trigger">{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</span>
          </div>
          {breadcrumb ? breadcrumb : null}
        </div>
        <div className="actions">
          {logged ? (
            <div>
              <Avatar src={<img src={userInfo?.headImg || LogoConfig.DefaultAvatar} />} style={{ marginRight: 10 }} />
              <Dropdown menu={menus}>
                <span className="user-action">
                  {userInfo?.nickname}
                  {userInfo?.phone}
                </span>
              </Dropdown>
            </div>
          ) : (
            <span style={{ cursor: 'pointer' }} onClick={toLogin}>
              登录
            </span>
          )}
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
