import { Navigate } from 'react-router-dom';
import WrapperRouteComponent from '../config';
import OrderList from '@/pages/distribution/orderList';
import UserList from '@/pages/distribution/userList';

export const DistributionRoute = [
  {
    path: 'distribution',
    children: [
      { path: '', element: <Navigate to="/distribution/orderList" /> }, // Redirect
      {
        path: 'orderList',
        element: <WrapperRouteComponent element={<OrderList />} titleId="orderList" />,
      },
      {
        path: 'userList',
        element: <WrapperRouteComponent element={<UserList />} titleId="userList" />,
      },
    ],
  },
];
