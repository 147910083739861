import request from './request';
import { AxiosRequestConfig } from 'axios';
import { PageData } from '@/interface';
import { IOrder, IUser, IDistributionInfo } from '@/interface/distribution';

// 获取用户列表
export function getUserList(data?: object, config?: AxiosRequestConfig) {
  return request<PageData<IUser>>({
    url: `/distribution/api/customer`,
    method: 'post',
    data,
    ...config,
  });
}

// 获取订单列表
export function getOrderList(data?: object, config?: AxiosRequestConfig) {
  return request<PageData<IOrder>>({
    url: `/distribution/api/order`,
    method: 'post',
    data,
    ...config,
  });
}

// 获取当前用户的分销基本信息
export function getDistributionInfo() {
  return request<IDistributionInfo>({
    url: `/distribution/api/distributionInfo`,
    method: 'post',
  });
}
