import { IUserInfo } from '@/interface/user';
import request from './request';

const config = {
  baseURL: process.env.REACT_APP_FEI_API,
};

// 获取当前用户登录信息
export function getUserInfo() {
  return request<IUserInfo>({
    url: `/user/info`,
    method: 'get',
    ...config,
  });
}

// 退出登录
export function logOut() {
  return request<boolean>({
    method: 'GET',
    url: '/user/logout',
    ...config,
  });
}

// 密码登录
export function loginPassword(data: { phone: string; pass: string }) {
  return request<string>({
    method: 'POST',
    url: `/user/login/passwd`,
    data,
    ...config,
  });
}

// 发送短信验证码
export function sendVerifyCode(params?: string) {
  return request<string>({
    method: 'GET',
    url: `/user/login/verification/${params}`,
    ...config,
  });
}

// 短信验证码登录
export function loginVerifyCode(data: { phone: string; code: string }) {
  return request<string>({
    method: 'POST',
    url: `/user/login/code`,
    data,
    ...config,
  });
}
