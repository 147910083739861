import { lazy } from 'react';
import LayoutPage from 'pages/layout';
import { RouteObject } from 'react-router';
import WrapperRouteComponent from './config';
import { useRoutes } from 'react-router-dom';
import { DistributionRoute } from './distribution';
import HomePage from '@/pages/home';
import LoginPage from '@/pages/login';

const NotFound = lazy(() => import(/* webpackChunkName: "404'"*/ 'pages/404'));

const routeList: RouteObject[] = [
  {
    path: '/',
    element: <WrapperRouteComponent element={<LayoutPage />} titleId="" />,
    children: [
      {
        path: '/home',
        element: <WrapperRouteComponent element={<HomePage />} titleId="home" />,
      },

      ...DistributionRoute,
      {
        path: '*',
        element: <WrapperRouteComponent element={<NotFound />} titleId="notFount" />,
      },
    ],
  },
  {
    path: '/login',
    element: <WrapperRouteComponent element={<LoginPage />} titleId="login" />,
  },
];

const RenderRouter = () => {
  const element = useRoutes(routeList);
  return element;
};

export default RenderRouter;
