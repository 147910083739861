import { IUserInfo } from '@/interface/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppGlobalState {
  collapsed: boolean; // 菜单收纳状态
  logged: boolean; // 判断是否登录
  userInfo: IUserInfo | null; // 用户信息存储
}

const initState: AppGlobalState = {
  collapsed: false,
  logged: false,
  userInfo: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initState,
  reducers: {
    setCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload;
    },
    setLogged: (state, action: PayloadAction<boolean>) => {
      state.logged = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setCollapsed, setLogged, setUserInfo } = globalSlice.actions;

export default globalSlice.reducer;
