import { useEffect, Suspense, useCallback, useState } from 'react';
import { Layout, Drawer } from 'antd';
import './index.less';
import HeaderComponent from './header';
import MenuComponent from './menu';
import BreadcrumbComponent from './breadcrumb';

import SuspendFallbackLoading from './suspendFallbackLoading';
// import { getMenuList } from 'apis/layout';
import { MenuList } from 'interface/layout/menu.interface';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useAppSelector, useAppDispatch } from '@/stores/useReduxHook';
import { setCollapsed, setLogged, setUserInfo } from '@/stores/slicers/global';
import { permissionMenu } from '@/routes/permissionMenu';
import { useRequest } from 'ahooks';
import { getUserInfo } from '@/apis/user';

const { Sider, Content } = Layout;
const WIDTH = 992;

const LayoutPage = () => {
  const [menuList, setMenuList] = useState<MenuList>([]);
  const { device } = { device: 'DESKTOP' }; // get from redux
  const collapsed = useAppSelector(state => state.appGlobal.collapsed);
  const dispatch = useAppDispatch();
  const isMobile = device === 'MOBILE';

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home');
    }
  }, [navigate, location]);

  const toggle = () => {
    dispatch(setCollapsed(!collapsed));
  };

  // const initMenuListAll = (menu: MenuList) => {
  //   const MenuListAll: MenuChild[] = [];
  //   menu.forEach(m => {
  //     if (!m?.children?.length) {
  //       MenuListAll.push(m);
  //     } else {
  //       m?.children.forEach(mu => {
  //         MenuListAll.push(mu);
  //       });
  //     }
  //   });
  //   return MenuListAll;
  // };
  // console.log(initMenuListAll);

  const fetchMenuList = useCallback(async () => {
    // const result = await getMenuList();
    setMenuList(permissionMenu);
  }, []);

  useEffect(() => {
    fetchMenuList();
  }, [fetchMenuList]);

  useEffect(() => {
    window.onresize = () => {
      const rect = document.body.getBoundingClientRect();
      const needCollapse = rect.width < WIDTH;
      dispatch(setCollapsed(needCollapse));
    };
  }, [dispatch]);

  /**
   * 请求当前用户数据
   */
  useRequest(getUserInfo, {
    onSuccess: res => {
      if (res?.data) {
        dispatch(setUserInfo(res.data));
        dispatch(setLogged(true));
      }
    },
  });

  return (
    <Layout className="layout-page">
      <HeaderComponent collapsed={collapsed} toggle={toggle} breadcrumb={<BreadcrumbComponent menuList={menuList} />} />
      <Layout>
        {!isMobile ? (
          <Sider
            className="layout-page-sider"
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="md"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            <MenuComponent menuList={menuList} />
          </Sider>
        ) : (
          <Drawer
            width="200"
            placement="left"
            bodyStyle={{ padding: 0, height: '100%' }}
            closable={false}
            onClose={toggle}
            visible={!collapsed}
          >
            <MenuComponent menuList={menuList} />
          </Drawer>
        )}

        <Content className="layout-page-content">
          <Suspense
            fallback={
              <SuspendFallbackLoading
                message="Alert message title"
                description="Further details about the context of this alert."
              />
            }
          >
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
