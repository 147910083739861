/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time: object | string | number, cFormat?: string) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

export function sleep(millseconds: number) {
  return new Promise(resolve => setTimeout(resolve, millseconds));
}

/**
 * 获取地址栏url ?参数，返回键值对对象
 */
export const getQuery = (): CommonObjectType<string> => {
  const { href } = window.location;
  const query = href.split('?');
  if (!query[1]) return {};

  const queryArr = decodeURI(query[1]).split('&');
  const queryObj = queryArr.reduce((prev, next) => {
    const item = next.split('=');
    return { ...prev, [item[0]]: item[1] };
  }, {});
  return queryObj;
};

export const getCookie = (key: string) => {
  if (document.cookie) {
    const cookieInfo = document.cookie;
    const arr = cookieInfo.replace(/;\s/g, ';').split(';');
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i].split('=');
      if (item[0] === key) {
        return item[1];
      }
    }
    return null;
  }
  return null;
};
