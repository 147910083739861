import axios from 'axios';
import { message } from 'antd';
import { getCookie } from '@/utils/commonUtils';

const service = axios.create({
  baseURL: process.env.REACT_APP_DISTRIBUTION_API,
  timeout: 15000,
  withCredentials: true, // send cookies when cross-domain request
});

service.interceptors.request.use(
  config => {
    const cookie = getCookie('fly_user_token');
    if (cookie) {
      localStorage.setItem('fly_user_token', cookie);
    }
    const token = cookie || localStorage.getItem('fly_user_token');
    if (token) {
      config.headers['fly_user_token'] = token;
      localStorage.setItem('fly_user_token', token);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code === 200) {
      return res;
    } else if (res.code === 1402 || res.code === 9995) {
      message.error({
        type: 'error',
        content: res.message,
      });
      window.location.href = process.env.REACT_APP_USER_URL + 'login';
    } else {
      message.error({
        type: 'error',
        content: res.message,
      });
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误: ';
          break;
        case 401:
          error.message = '请求无权限';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = `请求资源失败： `;
          return;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实施';
          break;
        case 502:
          error.message = '网关错误';
          break;
        case 503:
          error.message = '服务暂停中';
          break;
        case 504:
          error.message = '请求网关超时';
          break;
        case 505:
          error.message = '不支持 HTTP 版本';
          break;

        default:
      }
      if (error.response.data) {
        try {
          let errMsgObj;
          if (typeof error.response.data === 'string') {
            errMsgObj = JSON.parse(error.response.data);
          } else if (typeof error.response.data === 'object') {
            errMsgObj = error.response.data;
          }
          error.message = errMsgObj.message;
        } catch (err) {
          console.error(err);
        }
      }
    }
    if (error.message) {
      message.error({
        type: 'error',
        content: error.message,
      });
    }
    return Promise.reject(error);
  }
);

export default service.request;
