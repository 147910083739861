/**
 * 路由权限配置：目前前端处理
 */
export const permissionMenu = [
  {
    name: '首页',
    icon: 'BankOutlined',
    key: '0',
    path: '/home',
  },
  {
    name: '分佣管理',
    icon: 'AppstoreOutlined',
    key: '1',
    path: '/distribution',
    children: [
      {
        name: '订单列表',
        key: '1-0',
        path: '/distribution/orderList',
      },
      {
        name: '用户列表',
        key: '1-1',
        path: '/distribution/userList',
      },
    ],
  },
];
