import React, { useRef } from 'react';
import { Card, Select } from 'antd';
import MyTable from '@/components/table';
import { getUserList } from '@/apis/distribution';

export default function UserList() {
  const tableRef: RefType = useRef();

  const onSearchItemChange = changedField => {
    console.log(changedField);
  };

  // 搜索栏配置项
  const searchItemList = [
    {
      key: 'custLevel',
      slot: (
        <Select
          placeholder="请选择用户类型"
          allowClear
          options={[
            { value: 1, label: '一级分销用户' },
            { value: 2, label: '二级分销用户' },
            { value: 3, label: '三级分销用户' },
          ]}
        />
      ),
      initialValue: 1,
    },
  ];

  const columns = [
    {
      title: '账号',
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '会员姓名',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '邀请时间',
      align: 'center',
      dataIndex: 'inviateDateStr',
    },
  ];

  return (
    <Card>
      <MyTable
        apiFun={getUserList}
        columns={columns}
        ref={tableRef}
        searchItemList={searchItemList}
        onFieldsChange={onSearchItemChange}
      />
    </Card>
  );
}
