import './App.less';
import { BrowserRouter } from 'react-router-dom';
import RenderRouter from './routes';
import { ConfigProvider, Watermark } from 'antd';
import { useAppSelector } from './stores/useReduxHook';
import zhCN from 'antd/es/locale/zh_CN';

function App() {
  const { userInfo } = useAppSelector(state => state.appGlobal);

  return (
    <ConfigProvider locale={zhCN}>
      <Watermark content={[userInfo?.nickname, userInfo?.phone]}>
        <BrowserRouter>
          <RenderRouter></RenderRouter>
        </BrowserRouter>
      </Watermark>
    </ConfigProvider>
  );
}

export default App;
