import { Button, Checkbox, Form, Input, message } from 'antd';
import './index.less';
import { useRequest } from 'ahooks';
import { loginPassword } from '@/apis/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgreementConfig } from '@/config/AgreementLink';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export default function LoginPage() {
  /**
   * 获取账号密码的值
   */
  const [phone, setPhone] = useState<string>();
  const phoneChange = event => {
    setPhone(event.target.value);
  };
  const [pass, setPass] = useState<string>();
  const passChange = event => {
    setPass(event.target.value);
  };
  const [check, setCheck] = useState<boolean>();
  const onChange = (e: CheckboxChangeEvent) => {
    setCheck(e.target.checked);
  };

  /**
   * 请求登录
   */
  const navigate = useNavigate();
  const { run: runLogin } = useRequest(loginPassword, {
    manual: true,
    onSuccess: res => {
      if (res?.data) {
        localStorage.setItem('fly_user_token', res?.data);
        navigate('/');
      }
    },
  });
  const loginBtn = (data: { phone: string; pass: string }) => {
    if (!data.phone) return message.error('请输入账号');
    if (!data.pass) return message.error('请输入密码');
    if (!check) return message.error('请阅读并勾选协议');

    runLogin(data);
  };

  return (
    <div className="contentWrapper">
      <div className="loginTitle" />
      <div className="loginContent">
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item label="账号" name="username" rules={[{ required: true, message: '请输入正确账号!' }]}>
            <Input size="large" placeholder="请输入账号" value={phone} onChange={e => phoneChange(e)} allowClear />
          </Form.Item>

          <Form.Item label="密码" name="password" rules={[{ required: true, message: '请输入正确密码!' }]}>
            <Input.Password
              size="large"
              placeholder="请输入密码"
              value={pass}
              onChange={e => passChange(e)}
              allowClear
            />
          </Form.Item>

          <Form.Item name="agreement" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
            <Checkbox onChange={onChange}>
              我已阅读并同意
              <a href={AgreementConfig.RulesUrl} target="_blank" rel="noreferrer">
                《飞链云版图分销反佣规则说明》
              </a>
              和
              <a href={AgreementConfig.RulesUrl} target="_blank" rel="noreferrer">
                《飞链云版图分佣协议》
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <Button type="primary" block size="large" onClick={() => loginBtn({ phone, pass })}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
